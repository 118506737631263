
import { Component, Vue } from 'vue-property-decorator'
import echarts from 'echarts'

@Component
export default class Index extends Vue {
  private searchInfo= {
    projectName: '',
    startTime: '',
    endTime: ''
  }

  private dataInfo={ issueNum: {} }

  private dateRange: string[]= []

  private chart1: any = null
  private chart2: any = null
  private chart3: any = null
  private chart4: any = null
  private chart5: any = null
  private chart6: any = null

  created () {
    this.dateRange = [this.$dayjs(this.$dayjs().subtract(1, 'month')).format('YYYY-MM'), this.$dayjs(this.$dayjs().subtract(1, 'month')).format('YYYY-MM')]
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
    // 清除图表
    this.chart1 && this.chart1.dispose()
    this.chart2 && this.chart2.dispose()
    this.chart3 && this.chart3.dispose()
    this.chart4 && this.chart4.dispose()
    this.chart5 && this.chart5.dispose()
    this.chart6 && this.chart6.dispose()

    this.chart1 = null
    this.chart2 = null
    this.chart3 = null
    this.chart4 = null
    this.chart5 = null
    this.chart6 = null
  }

  resize () {
    this.chart1 && this.chart1.resize()
    this.chart2 && this.chart2.resize()
    this.chart3 && this.chart3.resize()
    this.chart4 && this.chart4.resize()
    this.chart5 && this.chart5.resize()
    this.chart6 && this.chart6.resize()
  }

  getData () {
    const info = {
      projectName: this.searchInfo.projectName,
      startTime: this.dateRange[0],
      endTime: this.dateRange[1]
    }
    this.$axios.get(this.$apis.inspection.selectPatrolMonthlyReport, info).then(res => {
      this.dataInfo = res
      this.draw1(res.problemStatus)
      this.draw2(res.monthlyRectification)

      this.$nextTick(() => {
        this.chart3 = echarts.init(this.$refs.chart3 as any)
        this.chart5 = echarts.init(this.$refs.chart5 as any)
        this.chart6 = echarts.init(this.$refs.chart6 as any)
        this.drawCommon({
          y1: res.overtimeWorkOrder.issueNum,
          x1: res.overtimeWorkOrder.projectName,
          color: 'rgba(216, 95, 248, 1)',
          name: '巡查问题超时工单统计',
          chart: this.chart3
        })
        this.drawCommon({
          y1: res.frequency.woNum,
          x1: res.frequency.patrolUserName,
          color: 'rgba(50, 224, 157, 1)',
          name: '巡查次数统计',
          chart: this.chart5
        })
        this.drawCommon({
          y1: res.urge.urgeNum,
          x1: res.urge.projectName,
          color: 'rgba(248, 204, 75, 1)',
          name: '项目被催办工单数量统计',
          chart: this.chart6
        })
      })

      this.draw3(res.deductPoints)
    })
  }

  draw1 (list: string[]) {
    this.$nextTick(() => {
      this.chart1 = echarts.init(this.$refs.chart1 as any)
      const colors = [
        'rgba(50, 224, 157, 1)', 'rgba(121, 208, 247, 1)', 'rgba(248, 204, 75, 1)', 'rgba(111, 103, 228, 1)'
      ]
      const dataList: any = []
      const statusNameList: Array<string> = ['未整改', '待审核', '已完成', '待返工']
      list.forEach((item: string, index: number) => {
        dataList.push({
          name: statusNameList[index] || '',
          value: item,
          itemStyle: {
            color: colors[index],
            borderColor: '#fff',
            borderWidth: 8
          },
          labelLine: {
            showAbove: true,
            normal: {
              length: 20,
              length2: 30,
              lineStyle: {
                type: 'solid',
                width: 1,
                color: colors[index]
              }
            }
          }
        })
      })

      const option: any = {
        title: {
          show: true,
          text: '巡查问题状态统计',
          top: '0',
          textAlign: 'left',
          textStyle: {
            color: 'rgba(71, 71, 71, 1)',
            fontSize: 16,
            fontWeight: '500'
          }
        },
        tooltip: {
          backgroundColor: '#0A354E',
          borderColor: '#378BA1',
          borderWidth: 1,
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: 100,
          bottom: 0,
          left: 0,
          right: 0
        },
        series: [{
          type: 'pie',
          clockWise: true,
          radius: ['40%', '70%'],
          center: ['50%', '62%'],
          hoverAnimation: false,
          minAngle: 10,
          label: {
            show: true,
            position: 'outside',
            overflow: 'none',
            textStyle: {
              color: 'rgba(71, 71, 71, 1)',
              fontSize: 12
            }
          },
          data: dataList
        }
        ]
      }
      this.chart1.setOption(option)
    })
  }

  draw2 (data: any) {
    this.$nextTick(() => {
      this.chart2 = echarts.init(this.$refs.chart2 as any)
      const colors = ['rgba(248, 204, 75, 1)', 'rgba(50, 224, 157, 1)', 'rgba(121, 208, 247, 1)', 'rgba(111, 103, 228, 1)']
      const option: any = {
        tooltip: {
          color: colors,
          trigger: 'axis',
          backgroundColor: '#0A354E',
          borderColor: '#378BA1',
          borderWidth: 1,
          axisPointer: {
            type: 'shadow'
          }
        },
        title: {
          show: true,
          text: '项目巡查问题整改情况统计',
          top: '0',
          textAlign: 'left',
          textStyle: {
            color: 'rgba(71, 71, 71, 1)',
            fontSize: 16,
            fontWeight: '500'
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: 30,
          right: 0,
          bottom: 30,
          top: 50
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          bottom: 0,
          height: 10,
          startValue: 0,
          endValue: 2,
          borderColor: 'transparent',
          backgroundColor: '#fff',
          fillerColor: '#ccc',
          handleStyle: {
            opacity: 0
          },
          showDetail: false,
          showDataShadow: false,
          brushSelect: false
        }],
        xAxis: [{
          type: 'category',
          data: data.projectName,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(151, 151, 151, 1)',
              width: 1
            }
          },
          axisTick: {
            show: true
          },
          axisLabel: {
            show: true,
            formatter: (value: string) => {
              if (value.length > 8) {
                return `${value.substring(0, 8)}...`
              } else {
                return value
              }
            },
            textStyle: {
              color: 'rgba(71, 71, 71, 1)',
              fontSize: 12
            }
          }
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: 'rgba(71, 71, 71, 1)',
            fontSize: 12
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(237, 237, 237, 1)'
            }
          }
        }],
        series: [{
          name: '未整改',
          type: 'bar',
          data: data.issueStatus0,
          barMinHeight: 3,
          barMaxWidth: 12, // 柱子宽度
          barGap: 0, // 柱子之间间距
          itemStyle: {
            normal: {
              color: colors[0],
              opacity: 1
            }
          }
        }, {
          name: '待审核',
          type: 'bar',
          data: data.issueStatus1,
          barMinHeight: 3,
          barMaxWidth: 12, // 柱子宽度
          barGap: 0, // 柱子之间间距
          itemStyle: {
            normal: {
              color: colors[1],
              opacity: 1
            }
          }
        }, {
          name: '待返工',
          type: 'bar',
          data: data.issueStatus3,
          barMinHeight: 3,
          barMaxWidth: 12, // 柱子宽度
          barGap: 0, // 柱子之间间距
          itemStyle: {
            normal: {
              color: colors[2],
              opacity: 1
            }
          }
        }, {
          name: '已完成',
          type: 'bar',
          data: data.issueStatus2,
          barMinHeight: 3,
          barMaxWidth: 12, // 柱子宽度
          barGap: 0, // 柱子之间间距
          itemStyle: {
            normal: {
              color: colors[3],
              opacity: 1
            }
          }
        }]
      }
      this.chart2.setOption(option)
    })
  }

  draw3 (data: {woNum: string[]; issueNum: string[]; deduction: string[]; projectName: string[]}) {
    this.$nextTick(() => {
      this.chart4 = echarts.init(this.$refs.chart4 as any)
      const colors = ['rgba(50, 224, 157, 1)', 'rgba(248, 204, 75, 1)', 'rgba(119, 227, 251, 1)']
      const option: any = {
        tooltip: {
          color: colors,
          trigger: 'axis',
          backgroundColor: '#0A354E',
          borderColor: '#378BA1',
          borderWidth: 1,
          axisPointer: {
            type: 'shadow'
          }
        },
        title: {
          show: true,
          text: '项目被巡查次数、问题数及扣分统计',
          top: '0',
          textAlign: 'left',
          textStyle: {
            color: 'rgba(71, 71, 71, 1)',
            fontSize: 16,
            fontWeight: '500'
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: 30,
          right: 0,
          bottom: 30,
          top: 50
        },
        dataZoom: [{
          type: 'slider',
          show: true,
          bottom: 0,
          height: 10,
          startValue: 0,
          endValue: 2,
          borderColor: 'transparent',
          backgroundColor: '#fff',
          fillerColor: '#ccc',
          handleStyle: {
            opacity: 0
          },
          showDetail: false,
          showDataShadow: false,
          brushSelect: false
        }],
        xAxis: [{
          type: 'category',
          data: data.projectName,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(151, 151, 151, 1)',
              width: 1
            }
          },
          axisTick: {
            show: true
          },
          axisLabel: {
            show: true,
            formatter: (value: string) => {
              if (value.length > 8) {
                return `${value.substring(0, 8)}...`
              } else {
                return value
              }
            },
            textStyle: {
              color: 'rgba(71, 71, 71, 1)',
              fontSize: 12
            }
          }
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: 'rgba(71, 71, 71, 1)',
            fontSize: 12
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(237, 237, 237, 1)'
            }
          }
        }],
        series: [{
          name: '次数',
          type: 'bar',
          data: data.woNum,
          barMinHeight: 3,
          barMaxWidth: 12, // 柱子宽度
          barGap: 0, // 柱子之间间距
          itemStyle: {
            normal: {
              color: colors[0],
              opacity: 1
            }
          }
        }, {
          name: '问题数量',
          type: 'bar',
          data: data.issueNum,
          barMinHeight: 3,
          barMaxWidth: 12, // 柱子宽度
          barGap: 0, // 柱子之间间距
          itemStyle: {
            normal: {
              color: colors[1],
              opacity: 1
            }
          }
        }, {
          name: '扣除分值',
          type: 'bar',
          data: data.deduction,
          barMinHeight: 3,
          barMaxWidth: 12, // 柱子宽度
          barGap: 0, // 柱子之间间距
          itemStyle: {
            normal: {
              color: colors[2],
              opacity: 1
            }
          }
        }]
      }
      this.chart4.setOption(option)
    })
  }

  drawCommon (data: {y1: string[]; x1: string[]; color: string; name: string; chart: any}) {
    const option: any = {
      tooltip: {
        color: data.color,
        trigger: 'axis',
        backgroundColor: '#0A354E',
        borderColor: '#378BA1',
        borderWidth: 1,
        axisPointer: {
          type: 'shadow'
        }
      },
      title: {
        show: true,
        text: data.name,
        top: '0',
        textAlign: 'left',
        textStyle: {
          color: 'rgba(71, 71, 71, 1)',
          fontSize: 16,
          fontWeight: '500'
        }
      },
      legend: {
        show: false
      },
      grid: {
        left: 30,
        right: 0,
        bottom: 30,
        top: 50
      },
      dataZoom: [{
        type: 'slider',
        show: true,
        bottom: 0,
        height: 10,
        startValue: 0,
        endValue: 2,
        borderColor: 'transparent',
        backgroundColor: '#fff',
        fillerColor: '#ccc',
        handleStyle: {
          opacity: 0
        },
        showDetail: false,
        showDataShadow: false,
        brushSelect: false
      }],
      xAxis: [{
        type: 'category',
        data: data.x1,
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(151, 151, 151, 1)',
            width: 1
          }
        },
        axisTick: {
          show: true
        },
        axisLabel: {
          show: true,
          formatter: (value: string) => {
            if (value.length > 8) {
              return `${value.substring(0, 8)}...`
            } else {
              return value
            }
          },
          textStyle: {
            color: 'rgba(71, 71, 71, 1)',
            fontSize: 12
          }
        }
      }],
      yAxis: [{
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          color: 'rgba(71, 71, 71, 1)',
          fontSize: 12
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(237, 237, 237, 1)'
          }
        }
      }],
      series: [{
        name: data.name,
        type: 'bar',
        data: data.y1,
        barMinHeight: 3,
        barMaxWidth: 12, // 柱子宽度
        barGap: 8, // 柱子之间间距
        itemStyle: {
          normal: {
            color: data.color,
            opacity: 1
          }
        }
      }]
    }
    data.chart.setOption(option)
  }
}
